import { useEffect, useRef } from 'react'
import { useCookies } from 'react-cookie'

import '../style/index.scss'
import 'rc-slider/assets/index.css'
import Nav from '@/Components/Nav'
import Footer from '@/Components/Footer'
import CookieBanner from '@/Components/CookieBanner'
import UserProvider from '@/Contexts/UserProvider'

import { useRouter } from 'next/router'

import * as Sentry from '@sentry/node'
import { sendEvent, updateCookies } from '@/Lib/gtm'

import Clipboard from 'clipboard'
import '@/Components/ImageUpload/ReactDoka/react-doka/lib/doka.min.css'

Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.SENTRY_DSN,
    ignoreErrors: [
        'Element type is invalid: expected a string (for built-in components) or a class/function (for composite components) but got: undefined.',
    ],
})

import fromEntries from 'object.fromentries'
if (!Object.fromEntries) {
    fromEntries.shim()
}

import { appWithTranslation } from 'next-i18next'

const App = ({ Component, pageProps, err }) => {
    const router = useRouter()
    const { pathname } = router
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name'])

    useEffect(() => {
        updateCookies(router, cookies, setCookie, removeCookie)
        sendEvent('page_view')
        // this needs to be done here due to the fact that third_party signup happens via redirects
        // and a user can end up on virtually any page after signing up
        if (cookies['signupVia']) {
            sendEvent('signup', {
                event_data: { category: 'acquisition', label: `third_party_${cookies['signupVia']}` },
            })
            removeCookie('signupVia', { path: '/', domain: process.env.DOMAIN })
        }
    }, [])

    // we need this to trigger event firing on router changes as
    // otherwise the single page app would not capture the page_view
    const routeChangeStarted = useRef(false)
    useEffect(() => {
        const initiateRouteChange = () => {
            routeChangeStarted.current = true
        }
        router.events.on('routeChangeStart', initiateRouteChange)

        const handleRouteChange = () => {
            // only trigger when route change is genuine (i.e. has been iniated)
            if (routeChangeStarted.current) {
                sendEvent('page_view')
            }
        }
        router.events.on('routeChangeComplete', handleRouteChange)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
            router.events.off('routeChangeStart', initiateRouteChange)
            routeChangeStarted.current = false
        }
    }, [])

    // this is used for direct copy to clipboard of links / invites
    const clipboardRef = useRef(null)
    useEffect(() => {
        if (!clipboardRef.current) {
            // set clipbboard event listener https://clipboardjs.com/#example-text for invite links
            clipboardRef.current = new Clipboard('.clipboard')
        }
        return () => {
            if (clipboardRef.current) {
                clipboardRef.current.destroy()
            }
        }
    }, [])

    return (
        <UserProvider sentry={Sentry}>
            <Nav />
            <div style={{ minHeight: 'calc(100% - 112px)', overflowY: 'hidden' }}>
                <Component {...pageProps} err={err} />
            </div>
            {!pathname.includes('flow') && (
                <>
                    <CookieBanner />
                    <Footer />
                </>
            )}
        </UserProvider>
    )
}

// don't convert to <1></1> if simple react elements. Allows for usage of simple html elements in translations
const i18nOptions = {
    react: { transKeepBasicHtmlNodesFor: ['br', 'i', 'b', 'p', 'strong', 'bold'] },
}

export default appWithTranslation(App, i18nOptions)
