import { useState, useEffect, useRef } from 'react';
import { Overlay, OverlayBody, OverlayNumber, OverlayNumerInner } from './styles';

import { Container } from '@/Components/styles';

import { Row, Col } from 'react-bootstrap';

import useDebounce from '@/Hooks/useDebounce';
import usePrevious from '@/Hooks/usePrevious';

/**
 * @param  {String} overlayText  Accepts text or JSX component. Inner text of overlay
 * @param  {String} circleValue  The value in the circular component
 * @param  {String} setVisited   Function to set visited value (if required in parent component)
 * @param  {String} fadeOut      Time in milliseconds it takes for the component to fade out after starting to hover
 * @param  {String} fadeIn       Time in milliseconds it takes for the component to fade in
 */
const Guidance = ({
    overlayText,
    overlayDirection = 'up',
    overlayCentered = false,
    circleValue,
    setVisited,
    fadeOut,
    fadeIn,
    position = { top: '-1.5rem', left: '-1.5rem' },
}) => {
    const [hovering, setHovering] = useState(false);
    const debouncedHover = useDebounce(hovering, 50);
    const prevHover = usePrevious(debouncedHover);
    const timeoutRef = useRef(null);

    const [visible, setVisible] = useState(fadeIn ? false : true);

    useEffect(() => {
        if (!debouncedHover) {
            if (prevHover) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                    setVisible(false);
                    if (setVisited) {
                        setVisited(true);
                    }
                }, 200);
            }
            return;
        }
        if (fadeOut) {
            timeoutRef.current = setTimeout(() => {
                setVisible(false);
                if (setVisited) {
                    setVisited(true);
                }
            }, fadeOut);
        }
        return () => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        };
    }, [debouncedHover]);

    useEffect(() => {
        if (fadeIn) {
            timeoutRef.current = setTimeout(() => {
                setVisible(true);
            }, fadeIn);
        }

        return () => {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        };
    }, []);

    return (
        <>
            <Overlay
                onMouseOver={() => setHovering(true)}
                onMouseOut={() => setHovering(false)}
                hovering={debouncedHover}
                visible={visible}
                overlayDirection={overlayDirection}
                {...position}
            >
                <OverlayBody className="overlayBody" hovering={debouncedHover} overlayCentered={overlayCentered}>
                    {overlayText}
                </OverlayBody>
                <OverlayNumber className="overlayNumber" hovering={debouncedHover}>
                    <OverlayNumerInner className="overlayNumberInner" hovering={debouncedHover}>
                        {circleValue}
                    </OverlayNumerInner>
                </OverlayNumber>
            </Overlay>
        </>
    );
};

export const Test = () => {
    const [visitedOne, setVisitedOne] = useState(false);
    const [visitedTwo, setVisitedTwo] = useState(false);

    return (
        <Container>
            <Row style={{ marginTop: '100px', padding: '20px' }}>
                <Col xs={12} style={{ position: 'relative' }}>
                    <Guidance overlayText="First Guidance" circleValue="1" setVisited={setVisitedOne} fadeOut={1000} />
                    Lorem ipsum dolor sit amet,{' '}
                    {/* <span style={{ position: 'relative', color: 'blue' }}>
                        maximus molestie
                        {visitedOne && (
                            <Guidance
                                overlayText="This is the guidance"
                                circleValue="2"
                                setVisited={setVisitedTwo}
                                fadeIn={300}
                            />
                        )}
                    </span>{' '} */}
                    nisi. Aenean eget eros turpis. Integer ut pellentesque sem, at porta ex. Sed facilisis aliquam mi.
                    Integer tincidunt tempor pulvinar.
                </Col>
            </Row>
            <Row style={{ padding: '20px' }}>
                <Col xs={12}>
                    {visitedOne && (
                        <span style={{ color: '#e28215', fontWeight: 900 }}>
                            1: First reminder after hovering 1, 1 has fadeout of 3000
                        </span>
                    )}
                </Col>
            </Row>
            <Row style={{ padding: '20px' }}>
                <Col xs={12}>
                    {visitedTwo && (
                        <span style={{ color: '#e28215', fontWeight: 900 }}>2: Reminder after hovering 2</span>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Guidance;
