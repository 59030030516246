import { Navbar } from 'react-bootstrap'
import styled from 'styled-components'
import { device } from '@/Components/styles'

export const StyledFooter = styled(Navbar)`
    background-color: white;
    -webkit-box-shadow: 0 24px 38px 3px #f7f7f72e, 0 9px 46px 8px #ffffff2b, 0 11px 15px -7px #ffffff45;
    box-shadow: 0 24px 38px 3px #f7f7f72e, 0 9px 46px 8px #ffffff2b, 0 11px 15px -7px #ffffff45;
    border-top: 2px solid #e28215;
    min-eight: 88px;
    ${device.sm} {
        height: 58px;
    }
`

export const StyledA = styled.a`
    min-height: 30px;
    min-width: 30px;
    padding: 3px 4px 3px 4px;
    margin: 1px;
    display: inline-block;
`
