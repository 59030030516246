import React from 'react'
import Link from 'next/link'
import { StyledFooter, StyledA } from './styles'

const aStyle = { minHeight: '30px', padding: '3px', display: 'inline-block' }

const Footer = () => {
    return (
        <StyledFooter sticky="bottom">
            <div style={{ float: 'left' }}>© 2020 Copyright: ViTeach GmbH</div>
            <div style={{ float: 'right', marginLeft: 'auto' }}>
                {/* <Link href="/about">
                    <a>About</a>
                </Link>
                {" "}  */}
                <Link href="https://viteach.join.com/">
                    <StyledA>Jobs</StyledA>
                </Link>{' '}
                <Link href="/imprint">
                    <StyledA>Impressum</StyledA>
                </Link>{' '}
                <Link href="/data_privacy">
                    <StyledA>Datenschutzerklärung</StyledA>
                </Link>{' '}
                <Link href="/terms_and_conditions">
                    <StyledA>Geschäftsbedingungen</StyledA>
                </Link>
            </div>
        </StyledFooter>
    )
}

export default Footer
