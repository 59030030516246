import React, { useContext, useState, useEffect } from 'react'

import axios from 'axios'

import UserProvider from '@/Contexts/UserProvider'

import { Nav, Navbar, Button, Form } from 'react-bootstrap'

import Signup from '@/Components/SignupLoginModal'
import Guidance from '@/Components/Guidance'

import { StyledNav } from './styles'
import { FaUserAlt, FaLightbulb, FaMap } from 'react-icons/fa'

import { sendEvent } from '@/Lib/gtm'

import { useRouter } from 'next/router'

const AdminNavs = ({ user, baseUrl }) => {
    if (user.permissions?.role === 'admin') {
        return (
            <Nav.Link href={`${baseUrl}/admin/knowledgenodes`}>
                <b style={{ color: '#e28215' }}>
                    <u style={{ display: 'flex', alignItems: 'center' }}>
                        <FaMap style={{ marginRight: '10px' }} /> KnowledgeNodes
                    </u>
                </b>
            </Nav.Link>
        )
    } else {
        return null
    }
}

const LanguagePicker = () => {
    const router = useRouter()

    const changeLanguage = (e) => {
        const locale = e.target.value
        router.push(router.asPath, router.asPath, { locale })
    }

    return (
        <div style={{ marginLeft: '10px' }}>
            <Form.Control as="select" onChange={changeLanguage} defaultValue={router.locale}>
                <option value="de">DE</option>
                <option value="en">EN</option>
            </Form.Control>
        </div>
    )
}

const NavbarComponent = () => {
    const baseUrl = process.env.FRONT_END_URL

    const [show, setShow] = useState(false)

    const {
        logout,
        user: { data: user },
        user: { data: { onboarding } = {} },
        user: {
            data: { _id: userId },
        },
    } = useContext(UserProvider.context)

    const [visitedOnboardingFlag, setVisitedOnboardingFlag] = useState(false)

    useEffect(() => {
        if (visitedOnboardingFlag) {
            onboarding.navbar = false
            axios.post('/api/user/update', { onboarding }, { withCredentials: true })
        }
    }, [visitedOnboardingFlag])

    const Logout = userId && (
        <>
            <Button
                onClick={() => {
                    sendEvent('logout_button_clicked', { event_data: { category: 'behavior' } })
                    logout()
                    setShow(false)
                }}
            >
                Logout
            </Button>
        </>
    )

    const Login = !userId && (
        <>
            <div className="mr-auto" />
            <div>
                <Button onClick={() => setShow(true)}>Login</Button>
                <Signup show={show} setShow={setShow} />
            </div>
        </>
    )

    return (
        <StyledNav sticky="top" expand="md">
            <StyledNav.Brand href="/">
                <img
                    alt="company logo"
                    src="/assets/icons/viteach_logo.svg"
                    width="100"
                    height="50"
                    className="d-inline-block align-top"
                />
            </StyledNav.Brand>
            {userId && (
                <>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <StyledNav.Collapse id="basic-navbar-nav" style={{ justifyContent: 'space-between' }}>
                        <>
                            <Nav.Link
                                href={`${baseUrl}/profile`}
                                style={{ color: '#1575E2', display: 'flex', alignItems: 'center' }}
                            >
                                <FaUserAlt style={{ marginRight: '10px' }} /> {user.email}
                            </Nav.Link>
                            <Nav.Link href={`${baseUrl}/flow/overview`} style={{ position: 'relative' }}>
                                {onboarding?.navbar && (
                                    <Guidance
                                        overlayText={
                                            <p>
                                                Erkunde hier deine persönliche Lernumgebung und tausche dich mit Vi aus!
                                            </p>
                                        }
                                        circleValue={<FaLightbulb />}
                                        setVisited={setVisitedOnboardingFlag}
                                        fadeOut={5000}
                                        overlayDirection="down"
                                        overlayCentered={true}
                                        position={{ top: '-4px', left: '0px' }}
                                    />
                                )}
                                <b style={{ color: '#e28215' }}>
                                    <u style={{ display: 'flex', alignItems: 'center' }}>
                                        <FaLightbulb style={{ marginRight: '10px' }} /> Zu den Lernumgebungen
                                    </u>
                                </b>
                            </Nav.Link>
                            <AdminNavs user={user} baseUrl={baseUrl} />
                        </>
                        {Logout}
                    </StyledNav.Collapse>
                </>
            )}
            {/* <LanguagePicker /> */}
            {Login}
        </StyledNav>
    )
}

export default NavbarComponent
