import styled from 'styled-components';
import { device } from '@/Components/styles';

export const CookieBannerStyle = styled.div`
    position: fixed;
    bottom: 5px;
    left: 5px;
    z-index: 1000;
    display: flex;
    border: 1px solid #c3ced6;
    background: white;
    max-width: 350px;
`;

export const ClosingButton = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 5px 10px 0 0;
    font-size: 10px;
    cursor: pointer;
    ${device.xs} {
        font-size: 12px;
    }
    ${device.sm} {
        font-size: 14px;
    }
`;

export const CookieText = styled.div`
    padding: 2% 2% 2% 2%;
    font-size: 9px;
    ${device.xs} {
        font-size: 10px;
    }
    ${device.sm} {
        font-size: 11px;
    }
    ${device.lg} {
        font-size: 12px;
    }
`;
