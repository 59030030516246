import { useState, useEffect } from 'react';
import { CookieBannerStyle, ClosingButton, CookieText } from './styles';

import { useCookies } from 'react-cookie';

const CookieBanner = () => {
    const [cookies, setCookie] = useCookies(['cookie-name']);
    const [showBanner, setShowBanner] = useState(false);

    const onClose = () => {
        acknowledgeCookies();
        setShowBanner(false);
    };

    const acknowledgeCookies = () => {
        const today = new Date();
        const inOneMonth = new Date(today.setMonth(today.getMonth() + 1));
        setCookie('cookiesAcknowledged', true, { path: '/', expires: inOneMonth, domain: process.env.DOMAIN });
    };

    useEffect(() => {
        if (!cookies.cookiesAcknowledged) {
            setShowBanner(true);
        }
    }, []);

    if (showBanner) {
        return (
            <CookieBannerStyle>
                <CookieText>
                    ViTeach verwendet Cookies, um dir einen nutzerfreundlichen Service anzubieten. Durch die weitere
                    Nutzung unserer Webseite stimmst du der Nutzung von Cookies zu. Weitere Details erfährst du in
                    unserer <a href="/data_privacy">Datenschutzerklärung</a>.
                </CookieText>
                <ClosingButton onClick={() => onClose()}>
                    <b>X</b>
                </ClosingButton>
            </CookieBannerStyle>
        );
    } else {
        return <div />;
    }
};

export default CookieBanner;
