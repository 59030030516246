import styled, { keyframes } from 'styled-components';
import { device } from '@/Components/styles';

export const Overlay = styled.div`
    position: absolute;
    z-index: 10;
    ${(props) => `top: ${props.top}`};
    ${(props) => `left: ${props.left}`};
    ${(props) => `right: ${props.right}`};
    ${(props) => `bottom: ${props.bottom}`};

    ${(props) => {
        if (props.hovering) {
            return `.overlayBody {
                    opacity: 1;
                    visibility: visible;
                    transform: ${props.overlayDirection === 'up' ? 'translateY(-3.5rem)' : 'translateY(4.5rem)'};
                    }

                    .overlayNumber {
                        background-color: rgba(226, 130, 21, 0.4);
                    }

                    .overlayNumberInner {
                        background-color: rgb(226, 130, 21);
                    }`;
        } else {
            return null;
        }
    }};

    ${(props) => (!props.visible ? 'visibility: hidden' : 'visibility: visible')};
    ${(props) => (!props.visible ? 'opacity: 0' : 'opacity: 1')};

    transition: opacity 1500ms, visibility 1500ms;
`;

export const OverlayBody = styled.div`
    opacity: 0;
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    bottom: 0;
    width: 16rem;
    ${(props) => `left : ${props.overlayCentered ? '-8rem' : null}`};
    ${device.sm} {
        width: 24rem;
        ${(props) => `left : ${props.overlayCentered ? '-12rem' : null}`};
    }
    background-color: #e28215;
    color: white;
    border-radius: 4px;
    padding: 1rem;
    transition: opacity 200ms, visibility 200ms, transform 300ms cubic-bezier(0, 0, 0.2, 1);
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(226, 130, 21, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(226, 130, 21, 0.2);
`;

const pulse = keyframes`
    0% { box-shadow: 0 0 0 0 rgba(226, 130, 21, 0.4); }
    70% { box-shadow: 0 0 0 1rem transparent; }
    100% { box-shadow: 0 0 0 0 transparent; }
`;

export const OverlayNumber = styled.div`
    background-color: rgba(226, 130, 21, 0.3);
    color: white;
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms;
    animation: ${pulse} 3s infinite;
    ${(props) => {
        if (props.hovering) {
            return `animation: none`;
        }
    }};
`;

export const OverlayNumerInner = styled.div`
    background-color: rgba(226, 130, 21, 0.6);
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms;
`;
